* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.index {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  color: var(--color-light-white-2);
  background-color: transparent;
  overflow: hidden;
}

.index .text-section {
  width: 100%;
  height: 100vh;
  padding: var(--left-margin-3) 0 0 var(--left-margin-3);
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  background: var(--color-dark-black-5);
}

.index .text-section .video {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-left: calc(var(--left-margin-3) * -1);
  margin-top: calc((var(--left-margin-3) * -1) - 2px);
}

.index .text-section .video video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
}

.index .text-section .scroll {
  position: absolute;
  z-index: 3;
  border: solid;
  top: 80%;
  left: 50%;
  font-size: 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5vw .5vw;
  border-radius: 2vw;
  transform: scale(.8);
  animation: scroll 3.5s ease-in-out infinite;
  box-shadow: 0 0 50px var(--color-light-white-6);
  cursor: pointer;
}

@keyframes scroll {

  0% {
    transform: scale(.8) translateY(-30px);

  }

  25% {
    opacity: 1;
  }

  50% {
    transform: scale(.8) translateY(80px);
  }

  0%, 50%, 100% {
    /* transform: scale(.8) ; */
    opacity: 0;
  }

}

.index .text-section .title {
  font-size: 5.8vw;
  font-weight: 300;
  margin-top: 10px;
  margin-left: calc(var(--left-margin) - 2px);
  /* padding: 20px 105px ; */
  width: 650px;
  line-height: 80px;
  z-index: 1;

}

.index .text-section .title .word {}

.index .text-section .title .word .letter {}

.index .btn-prm {
  font-size: 1.5vw;
  margin: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-dark-black-8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: .5s ease-in-out;

}

.index .index-btn {
  margin-top: 10px;
  opacity: 0;
  transform: translate(0, 200px);
  margin-left: calc(var(--left-margin) - 2px);
  transition: 1.5s ease-out;
}

.index .index-btn.animation {
  opacity: 1;
  transform: translate(0, 0);
}


.index .btn-prm .arrow-right {
  font-size: 2.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  margin-left: 60px;
  transition: .5s ease-in-out;

}

.index .btn-prm:hover .arrow-right {
  margin-left: 100px;
}

.index .btn-prm:before {
  content: '';
  position: absolute;
  height: 1px;
  width: 0%;
  top: 100%;
  background-color: var(--color-light-white-2);
  transition: .5s ease-in-out;
}

.index .btn-prm:hover:before {
  width: 100%;

}

/* //////////////////////////// Showing //////////////////////////////////////////////// */
/* //////////////////////////// Showing //////////////////////////////////////////////// */
/* //////////////////////////// Showing //////////////////////////////////////////////// */
/* //////////////////////////// Showing //////////////////////////////////////////////// */
/* //////////////////////////// Showing //////////////////////////////////////////////// */

.index .showing {
  width: 100%;
  height: 650px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  overflow: hidden;
  padding: 0 0 0 var(--left-margin-3);
  color: var(--color-dark-black-8);
}

.index .showing .left-side-intro {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px;
  background: var(--color-light-white-2);
  color: var(--color-dark-black-2);

}

.index .showing .left-side-intro .title {
  width: 75%;
  margin: 20px 100px;
  font-size: 4vw;
  font-weight: 350;
  text-align: left;
}

.index .showing .left-side-intro .text {
  width: 75%;
  text-align: left;
  margin: 20px;
  font-size: 1.35vw;
  font-weight: 350;
  line-height: 33px;
}

.index .showing .right-side {
  width: 50%;
  height: 100%;
  background: var(--color-light-white-2);
  position: relative;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  color: var(--color-dark-black-2);
  border-left: var(--color-dark-black-8) solid 2px;
  overflow: hidden;
  align-items: center;
}

.index .showing .right-side .swip-hand {
  position: absolute;
  font-size: 2vw;
  width: 3vw;
  height: 3vw;
  background-color: var(--color-dark-black-2);
  color: var(--color-light-white-2);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 70%;
  z-index: 2;
  border: solid;
  border-radius: 100%;
  animation: circle 2.5s ease-in-out infinite;
}

.index .showing .right-side .swip-hand.stop {
  display: none;
}

@keyframes circle {

  0% {
    transform: translateX(50px);
  }

  25% {
    opacity: 1;
  }

  50% {
    transform: translateX(-50px);
  }

  0%,
  50%, 100% {
    opacity: 0;
  }


}

.index .showing .right-side .arrows {
  width: 100%;
  top: 50%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}

.index .showing .right-side .arrows .to-left,
.index .showing .right-side .arrows .to-right {
  color: var(--color-dark-black-8);
  font-size: 2vw;
  z-index: 3;
  cursor: pointer;
  height: min-content;
  transition: .5s;
}

.index .showing .right-side .arrows .to-left {
  transform: rotate(180deg);
}

.index .showing .right-side .arrows .to-right {
  left: 90%;
}


.index .showing .right-side .category-title {
  width: 100%;
  font-size: 1.1vw;
  font-weight: 500;
  text-align: center;
  margin: 20px 0;
}

.index .showing .right-side .watch-box {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: .5s ease-in-out;

}

.index .showing .right-side .watch-box img {
  width: 60%;
  position: absolute;
  object-fit: cover;
  transition: all .5s ease-in-out;
}

.index .showing .right-side .watch-box .title {
  position: absolute;
  font-size: 10vw;
  font-weight: 350;
  top: 10%;
  z-index: -1;
  white-space: nowrap;
}

.index .showing .right-side .watch-box .details {
  position: absolute;
  top: 65%;
  left: 70%;
  width: 100px;
  text-align: right;
}

.index .showing .right-side .swiper {
  width: 100%;
  height: 100%;
}

.index .showing .right-side .swiper .swiper-slide {
  width: max-content;
  display: flex;
  height: 100%;

}

.index .showing .right-side .swiper .swiper-slide a {
  width: 100%;
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  color: var(--color-dark-black-2);
  overflow: hidden;
}

.index .showing .right-side .swiper .swiper-button-next,
.index .showing .right-side .swiper .swiper-button-prev {
  color: var(--color-dark-black-2);
  padding: 3vw 1.2vw;
  transition: .4s;
}

.index .showing .right-side .swiper .swiper-button-next:hover,
.index .showing .right-side .swiper .swiper-button-prev:hover {
  background-color: var(--color-dark-black-2);
  color: var(--color-light-white-2);
}

.index .showing .right-side .swiper .swiper-button-next::after,
.index .showing .right-side .swiper .swiper-rtl .swiper-button-prev::after,
.index .showing .right-side .swiper .swiper-button-prev::after,
.index .showing .right-side .swiper .swiper-rtl .swiper-button-next::after {
  font-size: 2vw;
}



/* ////////////////////////////   showing2   //////////////////////////////////////////////// */
/* ////////////////////////////   showing2   //////////////////////////////////////////////// */
/* ////////////////////////////   showing2   //////////////////////////////////////////////// */
/* ////////////////////////////   showing2   //////////////////////////////////////////////// */
/* ////////////////////////////   showing2   //////////////////////////////////////////////// */

.index .showing2 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 100px var(--left-margin-3);
  color: var(--color-light-white-2);
  justify-content: flex-start;
  min-height: 1000px;
}

.index .showing2 .img {
  position: absolute;
  width: 46%;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 900px;
}

.index .showing2 .img img {
  width: 85%;
  height: 100%;
  object-fit: cover;

}

.index .showing2 .img .before {
  position: absolute;
  width: 85%;
  height: 100%;
  background-color: var(--color-dark-black-1);
}


.index .showing2 .title1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
  font-weight: 400;
  text-align: center;
  z-index: 2;
  margin-top: 250px;
}

.index .showing2 .title2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
  font-weight: 350;
  z-index: 2;
  margin: 10px;

}

.index .showing2 .text {
  z-index: 2;
  width: 40%;
  margin-left: 50%;
  margin-top: 50px;
  font-size: 1.3vw;
  padding: 10px 50px;
  line-height: 35px;
}

.index .showing2 .btn-showing {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  z-index: 2;
  margin-top: 70px;

}

.index .showing2 .btn-showing .showing2-btn {
  font-size: 1.4vw;
}

/* ////////////////////////////   showing3   //////////////////////////////////////////////// */
/* ////////////////////////////   showing3   //////////////////////////////////////////////// */
/* ////////////////////////////   showing3   //////////////////////////////////////////////// */
/* ////////////////////////////   showing3   //////////////////////////////////////////////// */
/* ////////////////////////////   showing3   //////////////////////////////////////////////// */

.index .showing3 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 100px var(--left-margin-3) 100px var(--left-margin-3);
  color: var(--color-light-white-2);
  margin: 100px 0;
  justify-content: center;
  align-items: center;

}

.index .showing3 .title1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1vw;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.index .showing3 .title2 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vw;
  font-weight: 300;
  text-align: center;
  margin-top: 50px;
  line-height: 75px;
}

.index .showing3 .imgs {
  width: 100%;
  height: 300px;
  display: flex;
  position: relative;
  padding: 50px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 100px;

}

.index .showing3 .imgs img {
  position: absolute;
  width: 60%;
  object-fit: cover;
}

.index .showing3 .text {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  padding: 100px;
  align-items: flex-start;
}

.index .showing3 .text .part1, .index .showing3 .text .part2 {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  font-size: 1.3vw;
  font-weight: 400;
  line-height: 35px;
  margin: 10px 40px;

}

.index .showing3 .btn-showing3 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: -40px;
  font-size: 1.5vw;
}

.index .btn-showing3 .btn-prm {
  font-size: 1.4vw;
}

/* ////////////////////////////   showing4   //////////////////////////////////////////////// */
/* ////////////////////////////   showing4   //////////////////////////////////////////////// */
/* ////////////////////////////   showing4   //////////////////////////////////////////////// */
/* ////////////////////////////   showing4   //////////////////////////////////////////////// */
/* ////////////////////////////   showing4   //////////////////////////////////////////////// */

.index .showing4 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 0 var(--left-margin-3) 100px var(--left-margin-3);
  color: var(--color-light-white-2);
  justify-content: flex-start;
}

.index .showing4 .img {
  position: absolute;
  width: 46%;
  padding: 120px 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
}

.index .showing4 .img img {
  width: 85%;
  height: 100%;
  object-fit: cover;

}

.index .showing4 .img .before {
  width: 85%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  background-color: var(--color-dark-black-1);
}

.index .showing4 .title1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
  font-weight: 500;
  text-align: center;
  z-index: 2;
  margin-top: 300px;
}

.index .showing4 .title2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
  font-weight: 350;
  z-index: 2;
  line-height: 65px;
  margin: 10px;
  padding: 0 20%;
  text-align: center;
}

.index .showing4 .text {
  z-index: 2;
  width: 45%;
  margin-left: 50%;
  margin-top: 50px;
  font-size: 1.3vw;
  padding: 10px 50px;
  line-height: 35px;
}

.index .showing4 .btn-showing {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  z-index: 2;
  margin-top: 70px;
  padding-right: 70px;
}

.index .showing4 .btn-showing .showing4-btn {
  font-size: 1.35vw;
}




/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */



/* ------------------------------------------ 1024-start ------------------------------------------ */


@media (max-width:1025px) {
  .index .text-section .title {
    font-size: 7.5vw;
  }

  .index .showing {
    height: 75vh;
  }

  .index .showing .left-side-intro .text {
    font-size: 1.1vw;
    font-weight: 400;
  }

  .index .showing3 .imgs img {
    width: 70%;
  }

  .index .showing3 {
    padding: 50px var(--left-margin-3);
  }
}

/* ------------------------------------------ 1024-end ------------------------------------------ */
/* ------------------------------------------ 800-start ------------------------------------------ */


@media (max-width:800px) {

  .index .text-section .title {
    font-size: 8vw;
    width: 60%;
  }
  .index .text-section .scroll{
    font-size: 2.5vw ;
    padding: 2vw 0.5vw;
  }
  .index .index-btn {
    font-size: 2.4vw;
    position: relative;
    left: calc(var(--left-margin-3) / -2);
    margin: 10px auto;
  }

  .index .btn-prm .arrow-right {
    font-size: 4vw;
  }

  .index .showing {
    padding: 0;
  }

  .index .showing .left-side-intro .text {
    font-size: 1.5vw;
    line-height: 25px;
    font-weight: 400;
  }

  .index .showing2 .img {
    width: 60%;
    height: 600px;
  }

  .index .showing2 .title1 {
    font-size: 2vw;
    margin-top: 100px;
  }

  .index .showing2 .title2 {
    font-size: 5.5vw;
  }

  .index .showing2 .text {
    font-size: 2vw;
    width: 65%;
    margin-left: 35%;
  }
  .index .showing2 .btn-showing .showing2-btn{
    font-size: 2.5vw;
  }

  .index .showing3 .title1 {
    font-size: 2vw;
  }

  .index .showing3 .title2 {
    font-size: 6vw;
    width: 60%;
  }

  .index .showing3 .imgs img {
    width: 85%;
  }

  .index .showing3 .text .part1,
  .index .showing3 .text .part2 {
    font-size: 2vw;
  }
  .index .showing3 .text{
    padding: 40Px;

  }
  .index .btn-showing3 .btn-prm{
    margin-top: 50px;
    font-size: 2.5vw;
  }
  .index .showing4 .title2{
    font-size: 5vw;
  }
  .index .showing4 .title1{
    margin-top: 200px;
    font-size: 1.5vw;
  }
  .index .showing4 .text{
    width: 65%;
    font-size: 2vw;
    margin-left: 35%;
  }
  .index .showing4 .img{
    width: 65%;
  }
  .index .showing4 .btn-showing .showing4-btn{
    font-size: 2.5vw;
  }
  .container .watch-details .watchs-same-category{
    display: none;
  }
}


/* ------------------------------------------ 800-end ------------------------------------------ */
/* ------------------------------------------ 460-start ------------------------------------------ */


@media (max-width:460px) {
  .index .text-section {
    align-items: center;
  }
  .index .text-section .scroll{
    font-size: 4vw ;
    padding: 4vw 1vw;
    border-radius: 3.5vw;
    margin-left: -10px;
  }
  .index .text-section .title {
    width: 100%;
    font-size: 11vw;
    line-height: 60px;
  }

  .index .index-btn {
    font-size: 3.5vw;
  }

  .index .btn-prm .arrow-right {
    font-size: 6vw;
  }

  .index .showing {
    flex-direction: column;
    height: auto;
  }

  .index .showing .left-side-intro,
  .index .showing .right-side {
    width: 100%;
    height: auto;
  }

  .index .showing .left-side-intro {
    padding: calc(var(--left-margin-3) / 2) 50px;
    justify-content: center;
    align-items: center;
    order: 2;
  }

  .index .showing .right-side {
    border: none;
    padding: calc(var(--left-margin-3) / 2);
    border-bottom: var(--color-dark-black-8) solid 2px;
    order: 1;
  }

  .index .showing .left-side-intro .title {
    width: max-content;
    font-size: 8vw;
    margin: 0;
    font-weight: 450;
  }

  .index .showing .left-side-intro .text {
    font-size: 3.5vw;
    line-height: 25px;
    width: 100%;
    text-align: center;
  }

  .index .showing .right-side .swiper {
    height: 60vh;
  }

  .index .showing .right-side .swip-hand {
    width: 10vw;
    height: 10vw;
    font-size: 7vw;
  }

  .index .showing .right-side .swiper .swiper-button-next::after,
  .index .showing .right-side .swiper .swiper-rtl .swiper-button-prev::after,
  .index .showing .right-side .swiper .swiper-button-prev::after,
  .index .showing .right-side .swiper .swiper-rtl .swiper-button-next::after {
    font-size: 6vw;
  }

  .index .showing .right-side .watch-box .details {
    font-size: 4vw;
  }

  .index .showing .right-side .watch-box .title {
    font-size: 15vw;
  }

  .index .showing .right-side .category-title {
    font-size: 3vw;
  }

  .index .showing2 .img {
    width: 90%;
    
  }
  .index .showing2 .img img{
    width: 100%;
  }
  .index .showing2{
    padding: 100px 10px;
    align-items: center;
  }

  
  .index .showing2 .title1 {
    font-size: 3vw;
    margin-top: 100px;
  }

  .index .showing2 .title2 {
    font-size: 7vw;
  }

  .index .showing2 .text {
    font-size: 3.5vw;
    width: 95%;
    text-align: center;
    margin:  0;
    margin-top: 50px;
  }
  .index .showing2 .btn-showing{
    justify-content: center;
    
  }
  .index .showing2 .btn-showing .showing2-btn{
    font-size: 3.5vw;
  }

  .index .showing3{
    padding: 0 var(--left-margin-3);
  }
  .index .showing3 .title1 {
    font-size: 3vw;
  }

  .index .showing3 .title2 {
    font-size: 8vw;
    width: 90%;
  }
  .index .showing3 .imgs{
    padding: 0;
    height: 30vh;
    margin: 50px 0;
  }
  .index .showing3 .imgs img {
    width: 100%;
  }

  .index .showing3 .text .part1,
  .index .showing3 .text .part2 {
    font-size: 4vw;
    width: 100%;
    margin: calc(var(--left-margin-3) / 2) 0 ;
    text-align: center;

  }
  .index .showing3 .text{
    padding: 0 calc(var(--left-margin-3) / 2);
    flex-direction: column;
  }
  .index .btn-showing3 .btn-prm{
    font-size: 3.5vw;
  }
  .index .showing3 .btn-showing3{
    margin: 0;
  }
  .index .showing4 .title1{
    font-size: 3vw;
    margin-top: 150px;
  }
  .index .showing4 .title2{
    font-size: 8vw;
    padding: 5%;
  }
  .index .showing4 .img{
    width: 100%;
  }
  .index .showing4 .img img{
    width: 100%;
  }
  .index .showing4{
    align-items: center;

  }
  .index .showing4 .text{
    width: 80%;
    font-size: 3.5vw;
    text-align: center;
    padding: 0;
    margin: 50px 0;
  }
  .index .showing4 .btn-showing{
    padding: 0;
    justify-content: center;
  }
  .index .showing4 .btn-showing .showing4-btn{
    font-size: 3.5vw;
  }
}


/* ------------------------------------------ 460-end ------------------------------------------ */
/* ------------------------------------------ 350-start ------------------------------------------ */


@media (max-width:350px) {
  .index .showing2 .img{
    width: 100%;
  }
}


/* ------------------------------------------ 350-end ------------------------------------------ */