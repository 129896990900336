.container .watchs {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: var(--left-margin-2) 0 0 var(--left-margin-2);
  color: var(--color-light-white-2);
  position: relative;
}





.container .watchs .mini-title {
  padding: 50px 20px 0 var(--left-margin-2);
  font-weight: 500;
}

.container .watchs .series-box {
  width: 100%;
  border-bottom: 2px solid var(--color-dark-black-10);
  overflow: hidden;

}

.container .watchs .series-box .category-title {
  font-size: 6vw;
  width: 100%;
  text-align: left;
  font-weight: 350;
  padding: 0px 0 40px var(--left-margin-2);
  border-bottom: 2px solid var(--color-dark-black-10);

}


.container .watchs .series-box .all-watchs {
  width: 100%;
  padding: 50px var(--left-margin-2);
  padding-right: calc(var(--left-margin-2) * 2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;

}

.container .watchs .series-box .all-watchs .watch-box {
  width: 280px;
  height: 480px;
  /* padding: 10px; */
  margin: 50px 10px;
  background-color: var(--color-light-white-2);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.container .watchs .series-box .all-watchs .watch-box .title {
  padding: 20px;
  color: var(--color-dark-black-1);
  font-size: 1.25vw;
  width: 100%;
  text-align: center;
}

.container .watchs .series-box .all-watchs .watch-box img {
  object-fit: cover;
  transition: .5s;
  width: 90%;
}

.container .watchs .series-box .all-watchs .watch-box .price {
  padding: 20px;
  color: var(--color-dark-black-12);
  font-size: 1vw;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.container .watchs .series-box .all-watchs .watch-box .back-img {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  opacity: 0;
  transition: .5s;
}

.container .watchs .series-box .all-watchs .watch-box .back-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container .watchs .series-box .all-watchs .watch-box:hover .back-img {
  z-index: 1;
  opacity: 1;
}

.container .watchs .series-box .all-watchs .watch-box:hover img {
  transform: scale(1.1);
}

.container .watchs .end-text {
  width: 100%;
  padding: 50px var(--left-margin-2);
  padding-right: 30%;
  font-size: 1.5vw;
  text-align: left;
  border-bottom: 2px solid var(--color-dark-black-10);
  line-height: 35px;
}

.container .watchs .series-box .all-watchs .watch-box .fav-icon {
  position: absolute;
  padding: .8vw 1vw;
  border-radius: 4px;
  background-color: var(--color-dark-black-10);
  bottom: 3%;
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  font-size: 1.2vw;
  cursor: cell;
}

.container .watchs .series-box .all-watchs .watch-box .fav-icon-yes {
  color: rgb(255, 68, 68);
}

/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */



/* ------------------------------------------ 1024-start ------------------------------------------ */


@media (max-width:1025px) {

  .container .watchs .series-box .all-watchs .watch-box {
    height: 75vh;
    width: 40%;
  }

}

/* ------------------------------------------ 1024-end ------------------------------------------ */
/* ------------------------------------------ 800-start ------------------------------------------ */


@media (max-width:800px) {
  .container .watchs {
    padding: var(--left-margin-2) 0 0;
  }

  .container .watchs .series-box .all-watchs .watch-box {}

  .container .watchs .series-box .all-watchs .watch-box {
    height: 70vh;
    width: 40%;
  }

  .container .watchs .series-box .all-watchs .watch-box .title {
    font-size: 2vw;
  }

  .container .watchs .series-box .all-watchs .watch-box .price {
    font-size: 1.5vw;
  }

  .container .watchs .end-text {
    font-size: 2vw;
  }
}


/* ------------------------------------------ 800-end ------------------------------------------ */
/* ------------------------------------------ 460-start ------------------------------------------ */


@media (max-width:460px) {
  .container .watchs .mini-title {
    font-size: 6vw;
    text-align: center;
    padding: 30px 0 0;
  }

  .container .watchs .series-box .category-title {
    font-size: 10vw;
    text-align: center;
    padding: 30px 5px;

  }

  .container .watchs .series-box .all-watchs {
    padding: 30px var(--left-margin-2);
    justify-content: center;
  }

  .container .watchs .series-box .all-watchs .watch-box {
    width: 100%;
    height: 70vh;
  }

  .container .watchs .series-box .all-watchs .watch-box .price {
    font-size: 3vw;
  }

  .container .watchs .end-text {
    font-size: 3vw;
    padding: var(--left-margin-2);
    text-align: center;
  }

  .container .watchs .series-box .all-watchs .watch-box .title {
    font-size: 4vw;
  }

  .container .watchs .end-text:last-child {
    border: none;
  }


}


/* ------------------------------------------ 460-end ------------------------------------------ */
/* ------------------------------------------ 350-start ------------------------------------------ */


@media (max-width:350px) {
  .container .watchs .series-box .all-watchs .watch-box {}

  .container .watchs .series-box .all-watchs {
    padding: 30px var(--left-margin-3);
  }
}


/* ------------------------------------------ 350-end ------------------------------------------ */