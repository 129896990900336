.container .dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--color-light-white-2);
  overflow: hidden;
  font-weight: 350;
  font-size: 1.3vw;
  margin: 50px 0;
}

.container .account .big-box .dashboard header {
  width: 100%;
  font-weight: 500;
}
.container .account .big-box .dashboard header span{
  color: var(--color-light-red-1);

}

.container .account .big-box .dashboard footer {
  width: 100%;
  margin-top: 50px;
  font-weight: 400;
}

