.container .contact {
  width: 100%;
  height: 100%;
  padding: var(--left-margin-2);
  display: flex;
  flex-direction: column;
  color: var(--color-light-white-2);
  overflow: hidden;
  font-weight: 350;
}

.container .contact .title {
  font-size: 6vw;
  margin-top: 56px;
  padding-left: var(--left-margin-2);
  margin-bottom: 10px;
  width: 100%;
}

.container .contact .text {
  padding-left: var(--left-margin-2);
  padding-right: var(--left-margin-2);
  margin: 20px 0;
  width: 100%;
  font-size: 2.5vw;
}

.container .contact form {
  width: 100%;
  padding: 0 var(--left-margin-2);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 56px;
}

.container .contact form .input-box {
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.container .contact form .text-area-box {
  width: 100%;
}

.container .contact form .input-box .label,
.container .contact form .text-area-box .label {
  width: 100%;
  margin: 10px 0;
  font-size: 1.4vw;
}

.container .contact form .input-box input,
.container .contact form .text-area-box textarea {
  border: none;
  width: 100%;
  margin: 10px 0;
  height: 70px;
  background-color: transparent;
  padding: 10px 20px;
  border: solid 1px var(--color-dark-black-10);
  transition: .3s ease-in-out;
  font-size: 1.5vw;
  color: var(--color-light-white-2);
}

.container .contact form .input-box input:hover,
.container .contact form .text-area-box textarea:hover {
  border: var(--color-light-white-2) solid 1px;

}

.container .contact form .input-box input:focus,
.container .contact form .text-area-box textarea:focus {
  outline: none;
  border: var(--color-light-white-2) solid 1px;
}

.container .contact form .text-area-box textarea {
  width: 100%;
  height: 200px;

}

.container .contact form .contact-btn {
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .contact form .contact-btn .btn-prm {
  font-size: 1.3vw;
  margin: 10px;
  padding-bottom: 20px;
  color: var(--color-light-white-2);
  border-bottom: 1px solid var(--color-dark-black-8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: .5s ease-in-out;

}

.container .contact form .contact-btn .btn-prm .arrow-right {
  font-size: 2.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  margin-left: 60px;
  transition: .5s ease-in-out;

}

.container .contact form .contact-btn .btn-prm:hover .arrow-right {
  margin-left: 100px;
}

.container .contact form .contact-btn .btn-prm:before {
  content: '';
  position: absolute;
  height: 1px;
  width: 0%;
  top: 100%;
  background-color: var(--color-light-white-2);
  transition: .5s ease-in-out;
}

.container .contact form .contact-btn .btn-prm:hover:before {
  width: 100%;
}

.container .contact form .input-box .validate,
.container .contact form .text-area-box .validate {
  font-size: .9vw;
  color: var(--color-light-red-1);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 500;
  top: 100%;
  margin-top: 5px;
}

.container .contact form .input-box .validate .sym,
.container .contact form .text-area-box .validate .sym {
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2vw;
}

.container .contact form .error-box {
  padding: 30px;
  margin-bottom: 20px;
  font-size: 1.7vw;
  background-color: var(--color-dark-black-10);
  color: var(--color-light-white-2);
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  display: none;
}

.container .contact form .error-box.error-box-hidden {
  display: block;
  overflow: hidden;
}


/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */




/* ------------------------------------------ 800-start ------------------------------------------ */


@media (max-width:800px) {
  .container .contact{
    padding: var(--left-margin-2) 20px;
  }
  .container .contact .title{
    font-size: 8vw;
  }
  .container .contact .text{
    font-size: 3vw;
  }
  .container .contact form .input-box{
    width: 100%;
  }
  .container .contact form .input-box .label, 
  .container .contact form .text-area-box .label,
    .container .contact form .contact-btn .btn-prm,
  .container .contact form .input-box input, 
  .container .contact form .text-area-box textarea{
    font-size: 2.5vw;
  }
  .container .contact form .contact-btn .btn-prm .arrow-right{
    font-size: 3vw;
  }
  
}


/* ------------------------------------------ 800-end ------------------------------------------ */
/* ------------------------------------------ 460-start ------------------------------------------ */


@media (max-width:460px) {
  .container .contact .title,
  .container .contact .text,
  .container .contact form{
    padding: 0 30px;
  }
  .container .contact .title{
    font-size: 10vw;
  }
  .container .contact .text{
    font-size: 4vw;
  }
  .container .contact form .input-box .label, 
  .container .contact form .text-area-box .label,
    .container .contact form .contact-btn .btn-prm,
  .container .contact form .input-box input, 
  .container .contact form .text-area-box textarea{
    font-size: 3.5vw;
  }
  .container .contact form .input-box input{
    height: 50px;
  }
  .container .contact form .contact-btn .btn-prm .arrow-right{
    font-size: 5vw;
  }
}


/* ------------------------------------------ 460-end ------------------------------------------ */