.container .advice {
  width: 100%;
  height: 100%;
  padding: var(--left-margin-2) calc(var(--left-margin-2) * 2) ;
  display: flex;
  flex-direction: column;
  color: var(--color-light-white-2);
  overflow: hidden;
  font-weight: 350;
}
.container .advice .title{
  font-size: 6vw;
  width: 100%;
  margin-top: 56px;
}
.container .advice .intro{
  font-size: 2.3vw;
  margin: 20px 0 var(--left-margin-2) ;
  width: 100%;
  padding: 10px 0;
}
.container .advice .list{
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.4vw;
}
.container .advice .list ul{
  margin: 20px ;
}
.container .advice .list ul li{
  margin: 20px 0;
  padding-left:30px;
}
.container .advice .list ul li::marker{
  color: var(--color-light-white-3);
  margin-right: 20px;
}
.container .advice .list .mini-title{
  font-size: 1.6vw;
  margin: 10px 0;
  width: 100%;

}
.container .advice .groupe{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px 0;
}
.container .advice .groupe .pargh-title{
  font-size: 1.6vw;
  width: 100%;
  margin: 10px 0;
}
.container .advice .groupe .text{
  font-size: 1.4vw;
  width: 100%;
  line-height: 35px;
  margin: 20px 0;
}


/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */


/* ------------------------------------------ 800-start ------------------------------------------ */


@media (max-width:800px) {
  .container .advice .title{
    font-size: 8vw;
  }  
  .container .advice .intro,
  .container .advice .groupe .pargh-title{

    font-size: 3vw;
  }
  .container .advice .list .mini-title,
  .container .advice .groupe .text,
  .container .advice .list ul li{
    font-size: 2.5vw;
  }
}


/* ------------------------------------------ 800-end ------------------------------------------ */
/* ------------------------------------------ 460-start ------------------------------------------ */


@media (max-width:460px) {
  .container .advice{
    padding: var(--left-margin-2) 40px;

  }
  .container .advice .title{
    font-size: 10vw;
  }  
  .container .advice .intro,
  .container .advice .groupe .pargh-title{

    font-size: 5vw;
  }
  .container .advice .list .mini-title,
  .container .advice .groupe .text,
  .container .advice .list ul li{
    font-size: 4vw;
  }
}


/* ------------------------------------------ 460-end ------------------------------------------ */