.container .politic {
  width: 100%;
  height: 100%;
  padding: var(--left-margin-2) calc(var(--left-margin-2) * 2);
  display: flex;
  flex-direction: column;
  color: var(--color-light-white-2);
  overflow: hidden;
  font-weight: 350;
}

.container .politic .title {
  font-size: 6vw;
  width: 100%;
  margin-top: 56px;
}

.container .politic .intro {
  font-size: 2.3vw;
  margin: 20px 0 var(--left-margin-2);
  width: 100%;
  padding: 10px 0;
}

.container .politic .list {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.4vw;
}

.container .politic .list ul {
  margin: 20px;
}

.container .politic .list ul li {
  margin: 20px 0;
  padding-left: 30px;
}

.container .politic .list ul li::marker {
  color: var(--color-light-white-3);
  margin-right: 20px;
}

.container .politic .list .mini-title {
  font-size: 1.6vw;
  margin: 10px 0;
  width: 100%;

}

.container .politic .groupe {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px 0;
}

.container .politic .groupe .pargh-title {
  font-size: 1.6vw;
  width: 100%;
  margin: 10px 0;
}

.container .politic .groupe .text {
  font-size: 1.4vw;
  width: 100%;
  margin: 20px 0;
  line-height: 35px;
}


/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */



/* ------------------------------------------ 800-start ------------------------------------------ */


@media (max-width:800px) {
  .container .politic .title{
    font-size: 8vw;
  }
  .container .politic .groupe .pargh-title{
    font-size: 2.5vw;
  }
  .container .politic .groupe .text{
    font-size: 2vw;
  }

  .container .politic .list .mini-title{
    font-size: 2.2vw;
  }
  .container .politic .list ul li{
    font-size: 2.5vw;
  }
}


/* ------------------------------------------ 800-end ------------------------------------------ */
/* ------------------------------------------ 460-start ------------------------------------------ */


@media (max-width:460px) {
  .container .politic{
    padding-left: 50px;
    padding-right:50px ;
  }
  .container .politic .title{
    font-size: 10vw;
  }
  .container .politic .groupe .pargh-title{
    font-size: 4.5vw;
  }
  .container .politic .groupe .text{
    font-size: 4vw;
  }

  .container .politic .list .mini-title{
    font-size: 4.2vw;
  }
  .container .politic .list ul li{
    font-size: 4.5vw;
  }
}


/* ------------------------------------------ 460-end ------------------------------------------ */