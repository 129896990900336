.menu-page {
  width: 100%;
  height: 100%;
  position: fixed;
  padding: calc(var(--left-margin-2) - 2px) 0 0 var(--left-margin);
  display: flex;
  flex-direction: column;
  background-color: var(--color-dark-black-2);
  z-index: 8;
  overflow: hidden;
  color: var(--color-light-white-2);
  transition: 1s ease-in-out;
  top: -101%;
  opacity: 0;
}

.menu-page-open {
  top: 0%;
  opacity: 1;
}

.menu-page .all-titles {
  width: 100%;
  height: 55%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: transparent;
  overflow: hidden;

}

.menu-page .all-titles .service-client {
  display: flex;
  width: 30%;
  border-right: var(--color-dark-black-10) solid 2px;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px;
}

.menu-page .all-titles .service-client .title {
  color: var(--color-light-gray-2);
  font-size: 1.3vw;
  font-weight: 450;
  text-align: left;
  margin: 0 0 20px;
  padding-bottom: 10px;
}

.menu-page .all-titles .service-client .service {
  font-size: 1.5vw;
  font-weight: 350;
  text-align: left;
  padding: 12px 0;
  margin-top: -7px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.menu-page .all-titles .service-client .service::before {
  content: '';
  position: absolute;
  width: 100%;
  top: 90%;
  transform: translateX(-101%);
  background-color: var(--color-light-white-2);
  height: 1px;
  transition: .5s ease-in-out;
}

.menu-page .all-titles .service-client .service:hover:before {
  transform: translateX(0%);
}

.menu-page .all-titles .best-watch-saller {
  padding: 50px;
  border-right: var(--color-dark-black-10) solid 2px;
  width: 30%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
}

.menu-page .all-titles .best-watch-saller .title {
  font-size: 1.3vw;
  font-weight: 450;
  text-align: left;
  width: 100%;
  text-align: center;
  background-color: var(--color-dark-black-4);
  padding: 0 10px 10px;

}

.menu-page .all-titles .best-watch-saller .watch-title {
  font-size: 1.5vw;
  font-weight: 450;
  width: 100%;
  text-align: center;
  background-color: var(--color-dark-black-4);
  padding: 10px;
  margin-bottom: 50px;
}

.menu-page .all-titles .best-watch-saller .img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 10px;
  z-index: -1;
  transition: .4s ease-in-out;
  /* backdrop-filter: blur(0); */

}

.menu-page .all-titles .best-watch-saller .img img {
  width: 80%;
  object-fit: cover;

}

.menu-page .all-titles .best-watch-saller:hover .img {
  transform: scale(1.1);
  z-index: 1;
  /* backdrop-filter: blur(5px); */
}

.menu-page .all-titles .to-store {
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  background: linear-gradient(to left top, var(--color-dark-black-1), var(--color-light-white-1));
}

.menu-page .all-titles .to-store .title {
  text-align: center;
  color: var(--color-dark-black-2);
  text-transform: uppercase;
  position: relative;
  margin-top: -10px;
  overflow: hidden;
}

.menu-page .all-titles .to-store .title::before {
  content: '';
  position: absolute;
  width: 100%;
  top: 90%;
  transform: translateX(-101%);
  background-color: var(--color-dark-black-1);
  height: 1.5px;
  transition: .5s ease-in-out;
}

.menu-page .all-titles .to-store .title:hover:before {
  transform: translateX(0%);
}


.menu-page .all-titles .multi-contact {
  padding: 50px;
  padding-right: 115px;
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  height: 100%;
  margin-top: -10px;
  overflow: hidden;

}


.menu-page .all-titles .multi-contact .contact-box {
  width: 100%;
  margin: 15px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
}

.menu-page .all-titles .multi-contact .contact-box .title {
  color: var(--color-light-gray-2);
}

.menu-page .all-titles .multi-contact .contact-box .lien {
  margin-top: 10px;
  font-size: 1.2vw;
  overflow: hidden;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: -10px;
}

.menu-page .all-titles .multi-contact .contact-box .lien::before {
  content: '';
  position: absolute;
  width: 100%;
  top: 90%;
  transform: translateX(101%);
  background-color: var(--color-light-white-2);
  height: 1px;
  transition: .5s ease-in-out;
}

.menu-page .all-titles .multi-contact .contact-box .lien:hover:before {
  transform: translateX(0%);

}

.menu-page .menu-watchs {
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: var(--color-light-white-2);
  overflow: hidden;
  color: var(--color-dark-black-1);
}

.menu-page .menu-watchs .controller {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px;
  width: 30%;
  border-right: 2px solid var(--color-dark-black-10);

}

.menu-page .menu-watchs .controller .title {
  font-size: 3vw;
  font-weight: 350;
  padding-bottom: 12px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  color: var(--color-dark-black-1);
}

.menu-page .menu-watchs .controller .title::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  top: 90%;
  background-color: var(--color-dark-black-1);
  transform: translateX(-101%);
  transition: .5s ease-in-out;

}

.menu-page .menu-watchs .controller .title:hover:before {
  transform: translateX(0);
}


.menu-page .menu-watchs .controller .arrows {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 60px;
  padding: 0 20px;
  font-size: 2vw;
}

.menu-page .menu-watchs .controller .arrows .left-arrow, .menu-page .menu-watchs .controller .arrows .right-arrow {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-dark-black-10);
  transition: .4s ease-in-out;
  transform: translateX(0);
}

.menu-page .menu-watchs .controller .arrows .left-arrow {
  transform: rotate(180deg) translateX(0);
}



.menu-page .menu-watchs .watch {
  width: 100%;
  border-right: 2px solid var(--color-dark-black-10);
  display: flex;
  flex-direction: row;
  padding: 0 50px;
  transition: .5s ease-in-out;
  overflow: hidden;
  cursor: pointer;
  height: 100%;
}

.menu-page .menu-watchs .watch:hover {
  background-color: var(--color-dark-black-4);
}

.menu-page .menu-watchs .watch .left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.menu-page .menu-watchs .watch .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: .5s ease-in-out;
}

.menu-page .menu-watchs .watch:hover .left img {
  transform: scale(1.1);
}

.menu-page .menu-watchs .watch .right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-page .menu-watchs .watch .right .category {
  color: var(--color-light-gray-2);
  font-size: 1vw;
}

.menu-page .menu-watchs .watch .right .title {
  font-size: 1.5vw;
  text-align: center;

}



/* -------------------- */
/* -------------------- */
/* -------------------- */
/* -------------------- */
/* -------------------- */
/* -------------------- */

/* .menu-page .menu-watchs{
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: var(--color-light-white-2);
  overflow: hidden;
  color: var(--color-dark-black-1);
} */

.menu-page .menu-watchs .swiper {
  width: 70%;
  height: 100%;
}

.menu-page .menu-watchs .swiper .swiper-slide {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

}

.menu-page .menu-watchs .swiper .swiper-slide a {
  width: 100%;
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  color: var(--color-dark-black-2);
  overflow: hidden;
}

.menu-page .menu-watchs .swiper .swiper-button-next,
.menu-page .menu-watchs .swiper .swiper-button-prev {
  color: var(--color-light-white-2);
  padding: 1vw;
  background-color: var(--color-dark-black-2);
}

.menu-page .menu-watchs .swiper .swiper-button-next::after,
.menu-page .menu-watchs .swiper .swiper-rtl .swiper-button-prev::after,
.menu-page .menu-watchs .swiper .swiper-button-prev::after,
.menu-page .menu-watchs .swiper .swiper-rtl .swiper-button-next::after {
  font-size: 2vw;
}



/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */
/* ------------------------------------------ Media ------------------------------------------ */



/* ------------------------------------------ 1024-start ------------------------------------------ */


@media (max-width:1025px) {
  .menu-page .menu-watchs .watch .left img {
    height: 75%;
  }

  .menu-page .menu-watchs .controller {
    justify-content: center;
  }

  .menu-page .all-titles .best-watch-saller .img img {
    transform: scale(1.2);
  }

  .menu-page .all-titles .multi-contact .contact-box .title,
  .menu-page .all-titles .service-client .service,
  .menu-page .all-titles .best-watch-saller .watch-title,
  .menu-page .all-titles .best-watch-saller .title {

    font-size: 1.8vw;
  }

  .menu-page .all-titles .multi-contact .contact-box .lien,
  .menu-page .all-titles .service-client .title {
    font-size: 1.5vw;
  }
}

/* ------------------------------------------ 1024-end ------------------------------------------ */
/* ------------------------------------------ 800-start ------------------------------------------ */


@media (max-width:800px) {
  .menu-page .all-titles {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }

  .menu-page .all-titles .service-client,
  .menu-page .all-titles .best-watch-saller,
  .menu-page .all-titles .multi-contact,
  .menu-page .all-titles .to-store {
    width: 50%;
    height: 50%;
    border: var(--color-dark-black-10) solid 2px;
  }

  .menu-page .all-titles .to-store {
    display: flex;
  }

  .menu-page .all-titles .to-store .title {
    font-size: 4vw;
  }

  .menu-page .menu-watchs {
    display: none;
  }

  .menu-page .all-titles .multi-contact,
  .menu-page .all-titles .service-client {
    margin: 0;
    justify-content: center;
    padding: 10px 50px;
  }

  .menu-page .all-titles .multi-contact .contact-box {
    align-items: flex-start;
  }

  .menu-page .all-titles .best-watch-saller .img img {
    transform: scale(.9);
  }
}


/* ------------------------------------------ 800-end ------------------------------------------ */
/* ------------------------------------------ 460-start ------------------------------------------ */


@media (max-width:460px) {
  .menu-page .all-titles {
    flex-direction: column;
  }

  .menu-page .all-titles .best-watch-saller {
    display: none;
  }

  .menu-page .all-titles .service-client,
  .menu-page .all-titles .best-watch-saller,
  .menu-page .all-titles .multi-contact,
  .menu-page .all-titles .to-store {
    border: none;
    width: 100%;
    height: 33.333333333333%;
    overflow: hidden;
  }

  .menu-page .all-titles .multi-contact {
    border-bottom: 1px solid var(--color-dark-black-10);
    border-top: 1px solid var(--color-dark-black-10);
  }

  .menu-page .all-titles .to-store {
    justify-content: flex-start;
    padding: 10px 50px;
  }

  .menu-page .all-titles .to-store .title {
    font-size: 8vw;
  }

  .menu-page .all-titles .multi-contact .contact-box .lien,
  .menu-page .all-titles .service-client .title {
    font-size: 2.5vw;
  }

  .menu-page .all-titles .multi-contact .contact-box .title,
  .menu-page .all-titles .service-client .service,
  .menu-page .all-titles .best-watch-saller .watch-title,
  .menu-page .all-titles .best-watch-saller .title {
    font-size: 3vw;
  }

  .menu-page .all-titles .multi-contact .contact-box {
    margin: 7px 0;
  }

  .menu-page .all-titles .multi-contact .contact-box .lien {}

  .menu-page .all-titles .service-client .title {
    margin-bottom: 15px;
  }

  .menu-page .all-titles .multi-contact .contact-box .title {
    margin-bottom: -5px;
  }
}


/* ------------------------------------------ 460-end ------------------------------------------ */
/* ------------------------------------------ 350-start ------------------------------------------ */


@media (max-width:350px) {

  .menu-page .all-titles .multi-contact .contact-box .lien,
  .menu-page .all-titles .service-client .title {
    font-size: 3vw;
  }

  .menu-page .all-titles .multi-contact .contact-box .title,
  .menu-page .all-titles .service-client .service,
  .menu-page .all-titles .best-watch-saller .watch-title,
  .menu-page .all-titles .best-watch-saller .title {
    font-size: 3.5vw;
  }
  .menu-page .all-titles .to-store .title{
    font-size: 10vw;
  }
}


/* ------------------------------------------ 350-end ------------------------------------------ */