.container .orders {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--color-light-white-2);
  overflow: hidden;
  font-weight: 350;
  font-size: 1.3vw;
  margin: 50px 0;
}

.container .account .big-box .orders .empty {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 70px;
  background-color: var(--color-dark-black-10);
}
.container .account .big-box .orders .empty a{
  transition: .8s;
  font-weight: 500;
}
.container .account .big-box .orders .empty a:hover{
  transform: translateX(-5vw);
}
